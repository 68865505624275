.Dots{
    position: fixed;
    height: 100vh;
    color: white;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    width: 10%;
    max-width: 58px;
}

.Dots-Absolute{
    position: absolute;
    top: 0;
}
.Dots-circle {
    cursor: pointer;
    margin: 5px 0 5px 0;
    width: 12px;
    height: 12px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: rgb(233, 233, 233);
    transition:0.5s ease transform;
}
.Dots-circle:hover{
    background: white;
    transition: background 0.5s;
    /* transform: scale(1.1); */
}
.Dots-Active {
    background: #3584f7;
    transition: background 0.5s;
    /* transform: scale(1.4); */
}
.Dots-Active:hover {
    background: #4c93fd;
    transition: background 0.5s;
    /* transform: scale(1.5); */
    
}

.Dots-Sub-Active {
    background: #35e5f7;
    transition: background 0.5s;
    /* transform: scale(1.4); */
}
.Dots-Sub-Active:hover{
    transform: scale(1.6);
    /* background: #51eeff; */
}
.Dots-central{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Dots-SubCircle{
    width: 7px;
    height: 7px;
}


/* worms animation */

