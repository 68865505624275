.SubCard-Text{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    width: 100%;
    height: max-content;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    padding: 10%;
    font-weight: bold;
    min-height: 150px;
    box-sizing: border-box;
}