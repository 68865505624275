.Scrollyteller {
  position: relative;
  height: 100%;
  background-color: #051742;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
.Scrollyteller__section {
  position: sticky;
  width: 100%;
}
.hiddenclass {
  pointer-events: none;
}

img {
  max-width: 100%;
}
.left-side,
.left-side__lottie {
  height: 100vh;
  animation: fadeIn ease 2s !important;
  -webkit-animation: fadeIn ease 2s !important;
  -moz-animation: fadeIn ease 2s !important;
  -o-animation: fadeIn ease 2s !important;
  -ms-animation: fadeIn ease 2s !important;
}
.left-side {
  display: block;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.video {
  transition: opacity ease 0.01s;
}

.graphic {
  flex-basis: 50%;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}
.data {
  font-size: 5rem;
  text-align: center;
}

.step {
  text-align: center;
  width: 100%;
  height: max-content;
}
.step_header {
  height: 270vh;
}
.step_header {
  width: 100%;
  height: max-content;
}
.step_header .desc {
  width: 75%;
  max-width: 450px;
  padding-bottom: 35vh;
  padding-top: 0;
}
.step_header .desc:first-child {
  padding-top: 35vh;
}
.step_header .step {
  margin-bottom: 400px;
  position: absolute;
  left: 0px;
  right: 0px;
}
.step_footer {
  transition: opacity ease 0.5s;
}

.blurb {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3%;
  text-align: center;
  font-size: 24px;
  min-height: 50%;
}
.desc {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 50vh;
  padding-top: 50vh;
  /* height: 100vh; */
}
.desc:first-child {
  padding-top: 0;
}
.desc:last-child {
  padding-bottom: 0;
}
.btn {
  color: #575757;
}
.card-text-s {
  padding: 10%;
  font-size: 24px !important;
}

lottie-player {
  transition: all ease 100ms;
  height: 100vh;
}

.main {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mainmargin {
  margin-bottom: 300px;
}
@media screen and (max-width: 940px) {
  .main {
    grid-template-columns: 1fr 300px;
  }
}

@media screen and (max-width: 600px) {
  .main {
    display: block;
    grid-template-columns: 1fr;
  }
  .step {
    position: relative;

    opacity: 0.9;
    padding-top: 50px;
  }
  .desc {
    align-items: flex-start;
  }
  .step_header {
    transform: translateY(-50vh);
    height: 220vh;
  }
  .step_header .desc {
    width: 100%;
  }
}
.step__div {
  margin-bottom: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.div__waterAnimation {
  position: relative;
  top: 40;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
.div__waterAnimation__subdiv__flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookTimeBtn {
  background: rgb(96, 96, 96);
  padding: 15px 20px;
  border-radius: 30px;
  border: 2px solid white;
  width: 150px;
  cursor: pointer;
  position: absolute;
  top: calc(100vh - 100px);
  margin: auto;
  left: 0;
  right: 0;
}
.scroller {
  z-index: 2;
  padding: 100vh 0;
  display: flex;
  flex-direction: column;
}
.scrollerWithHeader{
  padding: 60vh 0;
}
.SlideLeft,
.SlideMedium,
.SlideRight{
  width: 50%;
}
.Fullscreen{
  width: 100%;
}
.SlideLeft{
  align-self: flex-start
}
.SlideMedium{
  align-self: center
}
.SlideRight{
  align-self: flex-end
}
@media screen and (max-width: 940px) {
  .SlideLeft,
.SlideMedium,
.SlideRight{
  width: 100%;
}
}