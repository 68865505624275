.w-card-maindiv {
  
  margin: 53vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  align-self: flex-end;
  height: max-content;
}
.w-card-text{
  position: absolute;
  left: 0;

}
.w-card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.w-card {
  display: flex;
  align-items: center;
  width: 80%;
  padding: 50vh 10%;
}
.w-card-first {
  padding-top: 0;
}
.w-card-last {
  padding-bottom: 0;
}
.w-card-maindiv-first {
  margin-top: 0;
}
.w-card-maindiv-last {
  margin-bottom: 0;
}
.card {
  width: 100%;
  height: max-content;
}
.card {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 10%;
  background: #fff;
  color: rgb(44, 44, 44);
  font-weight: bold;
  width: 80%;
  min-height: 150px;
  display: flex;
}
.card-body {
  display: grid;
  align-items: center;
  padding: 1.25rem;
}
.card-text {
  background: #ffffff08;
  color: white;
}
.card__notPadding {
  padding: 0%;
}

.w-card-translateY{
  transform: translateY(-50vh);
}

@media screen  and (max-width: 940px){
.w-card-maindiv {
  width: 100%;
  align-self: auto;
}
}